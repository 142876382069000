export const state = {
    session_id: null,
    user: null,
}

export const getters = {
    session_id(state) {
        return state.session_id
    },
    user(state) {
        return state.user
    }
}

export const mutations = {
    set_session(state, session_id) {
        state.session_id = session_id
    },
    set_user(state, user) {
        state.user = user
    }
}
