import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['session/session_id']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['session/session_id']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['session/session_id']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/account/logout')
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/fees',
        name: 'fees',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/fees')
    },
    {
        path: '/parkings',
        name: 'parkings',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/parking')
    },
    {
        path: '/employees',
        name: 'employees',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/employees')
    },
    {
        path: '/tickets',
        name: 'tickets',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/tickets')
    },
    {
        path: '/partners',
        name: 'partners',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/partners')
    },
    {
        path: '/partners/:id/statement',
        name: 'partnerStatement',
        meta: {
            authRequired: true
        },
        component: () =>  import('../views/pages/dashboard/partner_statement.vue')
    },
    {
        path: '/access-list',
        name: 'access-list',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/access')
    },
    {
        path: '/invoices',
        name: 'invoices',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/invoices')
    },
]
