const axios = require('axios')
const api_base = process.env.VUE_APP_API_BASE_URL

const register = async (user) => {
    const url = `${api_base}/v1/users`
    const result = await post(url, user)
    
    return result
}

const login = async (credentials) => {
    const url = `${api_base}/v1/users/login`
    const result = await post(url, credentials)
    
    return result
}

const logout = async () => {
    const url = `${api_base}/v1/users/logout`
    const session = localStorage.getItem('sesion_id')

    await post(url, {
        session_id: session
    })

    localStorage.clear()
}

const me = async () => {
    const session_id = localStorage.getItem('sesion_id')
    const url = `${api_base}/v1/users/me/${session_id}`
    const _me = await axios.get(url)
        .catch(() => {
            return {
                data: {
                    user: {
                        session_id: 'N/A'
                    }
                }
            }
        })

    return _me.data.user
}


const post = async (url, data) => {
    const result = await axios.post(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status
            
            return retError
        })

    return result
}

module.exports = {
    register,
    login,
    logout,
    me
}