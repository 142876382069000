import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import routes from './routes'

import store from '../state/store'
import { me } from '../services/user.service'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
    const session_id = store.getters['session/session_id']
    if(session_id) {
      localStorage.setItem('sesion_id', session_id)
    }

    const publicPages = ['/login', '/register', '/forgot-password']
    const authpage = !publicPages.includes(routeTo.path)
    const session = localStorage.getItem('sesion_id')
    const _me = await me()
    
    if (authpage && (!session || _me.session_id === 'N/A')) {
      return next('/login');
    }

    store.commit('session/set_user', _me)
    next();
})


export default router
